export class ProblemDetails {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  errors?: { [key: string]: string[] };

  constructor(init?: Partial<ProblemDetails>) {
    Object.assign(this, init);
  }

  static isOfErrorType(error: any): boolean {
    return error.status !== undefined
      && error.title !== undefined
      && error.type !== undefined;
  }

  concatErrors(separator: string = ' '): string {

    if (!this.errors) {
      return this.detail ?? '';
    }

    const uniqueErrors: string[] = [...new Set<string>(Object.values<string[]>(this.errors).flat())];

    return uniqueErrors.join(separator);
  }
}
