export interface UserDetails {
  personGuid: string;
  firstName: string;
  lastName: string;
  nnaId: number;
  email: string;
  orderGuid: string;
  isB2B: boolean;
}

export const mockUser: UserDetails = {
  firstName: "Roberto",
  lastName: "Haynes",
  nnaId: 847709471,
  orderGuid: "9ef0a761-b148-3ce9-5a2f-6898754fa3b2",
  personGuid: "9ff07d9e-c94e-c3c7-0e95-70f790ef0683",
  email: "",
  isB2B: true
};
