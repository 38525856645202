import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

export interface CalendarEvent {
  start: Date;
  end: Date;
  title: string;
  description?: string;
  url?: string;
  location: string;
  organization?: string;
  locale?: string;
  reminderMinutes?: number;
}

@Injectable({
  providedIn: 'root'
})
export class IcsService {

  constructor()
  {}

  public download(calendarEvent: CalendarEvent) {

    const startDate = DateTime.fromJSDate(calendarEvent.start).toFormat('yyyyMMdd')
    const endDate = DateTime.fromJSDate(calendarEvent.start).plus({ days: 1}).toFormat('yyyyMMdd')

    const ics = this.generateIcs(
        startDate,
        endDate,
        calendarEvent.title,
        calendarEvent.description || '',
        calendarEvent.url || '',
        calendarEvent.location,
        calendarEvent.organization || 'default',
        calendarEvent.locale || 'EN',
        calendarEvent.reminderMinutes || 30
    );

    console.log(ics);

    const element = document.createElement('a');

    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(ics));
    element.setAttribute('download', `${calendarEvent.title}.ics`);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';

    element.click();
  }

  // tslint:disable-next-line: max-line-length
  private generateIcs(
      start: string,
      end: string,
      title: string,
      description: string,
      url: string,
      location: string,
      organization: string,
      locale: string,
      reminderMinutes?: number
  ) {
    const timeStamp = new Date().toISOString();
    const uuid = `${timeStamp}-uid@${organization}`;

    const event = `BEGIN:VCALENDAR
PRODID:-//Events Calendar//${organization} 1.0//${locale}
VERSION:2.0
METHOD:PUBLISH
BEGIN:VEVENT
DTSTAMP:${timeStamp}Z
DTSTART;VALUE=DATE:${start}
DTEND;VALUE=DATE:${end}
SUMMARY:${title}
DESCRIPTION:${description}
LOCATION:${location}
UID:${uuid}
${!!reminderMinutes ? `BEGIN:VALARM
TRIGGER:-PT${reminderMinutes}M
REPEAT:1
DURATION:PT15M
ACTION:DISPLAY
DESCRIPTION:Reminder
END:VALARM` : ''}
END:VEVENT
END:VCALENDAR`;
    return event;
  }
}
