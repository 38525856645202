import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class DateService {

  constructor() { }

  formatDate(date: Date, format: string = 'MM/DD/yyyy'): string {
    return DateTime.fromJSDate(new Date(date)).toFormat(format);
  }

  combineDateAndTimeString(date: Date, time: string, timezone: string = 'America/Los_Angeles'): Date {

    const splitTime = time.split(':');
    const hours = parseInt(splitTime[0]);
    const minutes = parseInt(splitTime[1]);

    return DateTime.fromJSDate(new Date(date))
      .setZone(timezone)
      .set({ hour: hours, minute: minutes })
      .toJSDate();
  }
}
