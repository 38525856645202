import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { UserDetails } from '../../models';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  getUserDetails(token: string): Observable<UserDetails> {

    if (!token) {
      return throwError(() => new Error('Unauthorized.'));
    }

    return of(jwtDecode(token) as unknown as UserDetails);
  }
}
