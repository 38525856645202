import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {

  private selector = 'globalLoader';

  private globalClass = 'global-loader';
  private hiddenClass = `${this.globalClass}-hidden`;
  private fadeInClass = `${this.globalClass}-fade-in`;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  hide(): void {

    const element = this.element;

    if(!element) {
      return;
    }

    if(element.classList.contains(this.hiddenClass)) {
      return;
    }

    element.classList.add(this.fadeInClass);

    setTimeout(() => {
      element.classList.add(this.hiddenClass);
      element.classList.remove(this.fadeInClass);
    }, 500);
  }

  private get element(): HTMLElement | null {
    return this.document.getElementById(this.selector);
  }
}
