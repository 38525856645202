export interface IListItem<T> {
  value: T;
  text: string;
  description: string | undefined;
}

export class ListItem<T> implements IListItem<T> {
  value: T;
  text: string;
  description: string;

  constructor(value: T, text: string, description: string = '') {
    this.value = value;
    this.text = text;
    this.description = description;
  }
}

export function CreateList(values: number[], single: string, plural: string, prefix: string = '', includeAll: boolean = false, allValue: number | null = null): ListItem<number | null>[] {


  const list = values.map(value => {

    const suffix = value === 1 ? single : plural;
    const text  = prefix ? `${prefix} ${value} ${suffix}` : `${value} ${suffix}`;

    return new ListItem<number | null>(value, text);
  });

  if(includeAll) {
    list.unshift(new ListItem(allValue, `All ${plural}`));
  }

  return list;
}
