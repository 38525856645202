import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProblemDetails } from '../../models';
@Injectable({
  providedIn: 'root'
})
export class ErrorsService {

  constructor(
  ) {}

  decodeErrorString(error: any, defaultMessage: string): string {
    if(!error) {
      return '';
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    error = error instanceof HttpErrorResponse ? error.error : error;

    if(ProblemDetails.isOfErrorType(error)) {
      return new ProblemDetails(error).concatErrors();
    }

    if(typeof error === 'string') {
      return error;
    }

    if(error.error && typeof error.error === 'string') {
      return error.error;
    }

    if(error.message && typeof error.message === 'string') {
      return error.message;
    }

    if(error.statusText && typeof error.statusText === 'string') {
      return error.statusText;
    }

    return defaultMessage;
  }
}
